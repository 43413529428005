//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { application } from "./application"

import BenutzerPasswordController from "./benutzer_password_controller"
application.register( 'benutzer-password', BenutzerPasswordController )

import NotificationController from "./notification_controller"
application.register( 'notification', NotificationController )

import TableController from "./table_controller"
application.register( 'table', TableController )
