//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

// Hotwire
import { Turbo } from "@hotwired/turbo-rails"
// Turbo.session.drive = false

// Stimulus controllers
import "./controllers"

// Bootstrap
import * as bootstrap from "bootstrap"
document.addEventListener( 'turbo:load', () =>
{
  document.querySelectorAll( '[data-bs-toggle="popover"]' ).forEach( elt => { new bootstrap.Popover( elt ); } );
});

// Font Awesome
import '@fortawesome/fontawesome-free/js/all';