//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"

export default class extends Controller
{
  connect()
  {
    this.element.addEventListener( 'click', this.select );
  }

  // Table selected
  select( e )
  {
    let elt = e.target;

    while( elt.nodeName != 'TR' )
    {
      if( elt == null ) return;
      if( elt.nodeName == "A" ) return;
      if( elt.nodeName == "BUTTON" ) return;
      elt = elt.parentElement;
    }

    if( 'link' in elt.dataset )
    {
      let url = elt.dataset.link;

      if( 'target' in elt.dataset )
      {
        window.open( url, elt.dataset.target );
      }
      else
      {
        window.location = url;
      }
    }
  }
}